<template>
<div>

           <CCard class="cards">
            <CCardHeader>
              <CRow>
                <CCol col="6" class="styleHeader">{{$t('message.showEmployee')}} </CCol>
                <!-- <CCol col="6" class="styleHeader">{{$t('message.totalCachouts')}} :{{sumCachout}} </CCol> -->
              </CRow>
            </CCardHeader>
            <div class="body mt-3">
                <div>
       <div class="row">
        <div class="col-md-9">
         <div style="background-color: white; display:flex;flex-direction:row;">
            <div style="flex:1; ">

              <table class="table table-bordered">
                                <tbody>
                                    <tr>
                                        <th>{{$t('message.name')}} </th>
                                        <td v-if="$i18n.locale == 'English'">{{ employee.name}}</td>
                                        <td v-else-if="$i18n.locale == 'العربية' && employee.name_ar">{{ employee.name_ar }}</td>
                                        <td v-else>{{ employee.name}}</td>
                                    </tr>
                                    <tr>
                                        <th>{{$t('message.gender')}} </th>
                                        <td>{{ employee.gender }}</td>
                                    </tr>
                                    <tr>
                                        <th>{{$t('message.birthDate')}} </th>
                                        <td>{{ employee.birth_date }}</td>
                                    </tr>
                                    <tr v-if="employee.email!='undefined'">
                                        <th >  {{$t('message.employeeEmail')}}</th>
                                        <td>{{ employee.email }}</td>
                                    </tr>
                                     <tr>
                                        <th> {{$t('message.phone')}} </th>
                                        <td>{{ employee.phone }}</td>
                                    </tr>
                                     <tr>
                                        <th>  {{$t('message.jobName')}} </th>
                                        <td>{{  employee.job.name  }}</td>
                                    </tr>
                                     <tr>
                                        <th> {{$t('message.hireDate')}}  </th>
                                        <td>{{ employee.hire_date }}</td>
                                    </tr>
                                    <tr>
                                        <th> {{$t('message.contractType')}}  </th>
                                        <td>{{ employee.contract_type }}</td>
                                    </tr>
                                     <tr>
                                        <th> {{$t('message.salary')}}  </th>
                                        <td>{{ employee.salary| number }}</td>
                                    </tr>
                                      <tr>
                                        <th> {{$t('message.balance')}}  </th>
                                        <td>{{ employee.balance| number }}</td>
                                    </tr>
                                      <tr>
                                        <th> {{$t('message.bankAccount')}}  </th>
                                        <td>{{ employee.bank_account }}</td>
                                    </tr>

                                </tbody>

                            </table>

                  </div>
         </div>
           <div class="row" v-if="employee.salary_transactions[0]">
             <CCol col="9" style="font-size: 25px;text-align:center">{{ $t('message.totalCashout') }}  </CCol>
            <div class="col-md-12 m-auto">
                <table class="table table-bordered">
                  <tr>
                    <th>{{ $t('message.id') }}   </th>
                    <th> {{ $t('message.transactionType') }}  </th>
                     <th>  {{ $t('message.value') }}  </th>
                     <th>  {{ $t('message.statement') }}  </th>
                      <th>  {{ $t('message.date') }}  </th>
                  </tr>
                   <tr v-for=" transaction in employee.salary_transactions" :key="transaction.id">
                    <td>{{ transaction.id }}</td>
                    <td>{{ transaction.tran_type }}</td>
                     <td>{{ transaction.value }}</td>
                     <td>{{ transaction.statement }}</td>
                    <td>{{ moment(transaction.created_at).format("DD-MM-YYYY")   }}</td>
                  </tr>
                </table>
            </div>
          </div>
        </div>

      </div>
                </div>
                 <CButton color="btn btn-primary" @click="goBack">{{$t('message.back')}} </CButton>
            </div>
        </CCard>
</div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'ShowEmployee',
  data () {
    return {
      employee: [],
      sumCachout: [],
      moment: moment
    }
  },
  created () {
    console.log(this.$route.params.id)
    this.$http
      .get(`${this.$hostUrl}employees/${this.$route.params.id}`)
      .then((response) => {
        this.employee = response.data.data
      })
    // this.$http
    //   .get(`${this.$hostUrl}employees/${this.$route.params.id}/cash-outs/sum`)
    //   .then((response) => {
    //     this.sumCachout = response.data.data
    //   })
  },
  methods: {
    goBack () {
      this.customersOpened ? this.$router.go(-1) : this.$router.push({ path: '/employees' })
    }
  }
}
</script>
<style scoped>
span{
  font-size: 20px;
  margin-top: 10px;
}
table,td ,th{
    border: 1px solid #ddd;
  text-align: right;
  margin-right: 135px;
   margin-left: 135px;
}

th, td {
  padding: 15px;
}
  th {
    background-color: #643c4f;
    color: #f5f3f3;
  }
  .cont{
    display: inline;
    margin: 20px;
  }
  button{
    margin: 30px;
  }
  .left{
    margin: 30px;
  }
</style>
